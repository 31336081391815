<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('profile.notes.title')"></ProfileNavbar>
    <div class="mob-menu-title">
      <!--      <router-link tag="a" to="/profile" class="mob-menu-back">-->
      <!--        <img src="images/profile/arrow-back.svg">-->
      <!--      </router-link>-->
      {{ $t('profile.notes.title') }}
    </div>
    <div class="clearfix notes-wrapper">
      <div>
        <div class="notes-current-date">
          <div class="d-flex flex-row align-items-center">
            <div class="notes-current-day">
              {{ currentDay }}
            </div>
            <div>
              <div class="notes-current-week-day">
                {{ currentWeekDay }}
              </div>
              <div class="notes-current-month">
                {{ currentMonth }} {{ currentYear }}
              </div>
            </div>
          </div>
          <div class="notes-today">
            {{ $t("profile.notes.today") }}
          </div>
        </div>
        <div class="col-md-12 notes-tabs">
          <div style="display: none" class="tabsMenu">
            <div v-if="$i18n.locale=='ru'" class="card-notes">

              <span v-if="gender==1">Уважаемый,</span>
              <span v-if="gender==2">Уважаемая,</span> {{ testStudentName }}!
              <br>
              Пожалуйста, выберите дату для посещения Службы «Qabilet».
              <br>
              Внимание! Заранее планируйте день посещения. Записаться на услугу можно только
              {{ tries_per_month }} раз в месяц.
              <br>
              <br>
              Внимание! Старт Записи на посещение Центров Qabilet в регионах:
              <br>
              <br>
              город Нур-Султан, город Алматы, город Шымкент, Акмолинская область, Алматинская область,
              Восточно-Казахстанская область, Костанайская область – 11 октября 2021 года
              <br>
              <br>
              Атырауская область, Мангыстауская область, Актюбинская область, Западно-Казахстанская область – 25 октября
              2021 года
              <br>
              <br>
              Карагандинская область, Кызылординская область, Павлодарская область, Северо-Казахстанская область,
              Туркестанская область – 24 ноября 2021 года
            </div>
            <div v-else-if="$i18n.locale=='kz'" class="card-notes">

              Құрметті, {{ testStudentName }}!
              <br>
              "Qabilet" қызметіне келетін мерзімді таңдаңыз.
              <br>
              Назар аударыңыз! Келу күнін алдын-ала жоспарлаңыз. Сіз қызмет алуға айына {{ tries_per_month }} рет жазыла
              аласыз.
              <br>
              <br>
              Назар аударыңыз! Аймақтардағы Qabilet орталықтарына бару үшін жазылу басталды:
              <br>
              <br>
              Нұр-Сұлтан қаласы, Алматы қаласы, Шымкент қаласы, Ақмола облысы, Алматы облысы, Шығыс Қазақстан облысы,
              Қостанай облысы - 2021 жылдың 11 қазанынан
              <br>
              <br>
              Атырау облысы, Маңғыстау облысы, Ақтөбе облысы, Батыс Қазақстан облысы - 2021 жылдың 25 қазанынан
              <br>
              <br>
              Қарағанды облысы, Қызылорда облысы, Павлодар облысы, Солтүстік Қазақстан облысы, Түркістан облысы - 2021
              жылдың 24 қарашасынан
            </div>

            <ul class="nav nav-tabs d-flex">
              <li class="nav-item">
                <a class="nav-link notes-tabs-title text-center" @click.prevent="setActive('all')"
                   :class="{ active: isActive('all') }" href="#all">{{ $t("profile.notes.all-tab-title") }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link notes-tabs-title text-center" @click.prevent="setActive('consultations')"
                   :class="{ active: isActive('consultations') }" href="#consultations">
                  <span class="consultations-type">{{ $t("profile.notes.consultations-tab-title") }}</span>
                  <span class="consultations-type-mob">{{ $t("profile.notes.consultations-tab-title-mob") }}</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link notes-tabs-title text-center" @click.prevent="setActive('testing')"
                   :class="{ active: isActive('testing') }" href="#testing">
                  <span class="consultations-type">{{ $t("profile.notes.testing-tab-title") }}</span>
                  <span class="consultations-type-mob">{{ $t("profile.notes.testing-tab-title-mob") }}</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link notes-tabs-title text-center" @click.prevent="setActive('training')"
                   :class="{ active: isActive('training') }" href="#training">
                  <span class="consultations-type">{{ $t("profile.notes.training-tab-title") }}</span>
                  <span class="consultations-type-mob">{{ $t("profile.notes.training-tab-title-mob") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="bg-blue">
        <div class="tab-content mt-lg-3 mt-md-0 mb-4 pt-lg-0 pt-md-4" id="myTabContent">
          <div class="d-flex flex-row mb-4 justify-content-center justify-content-lg-start">
            <div class="d-flex flex-row align-items-center">
              <div class="mr-2 consultation-box"></div>
              <div>{{ $t("profile.notes.consultation") }}</div>
            </div>
            <div class="d-flex flex-row ml-4 align-items-center">
              <div class="mr-2 testing-box"></div>
              <div>{{ $t("profile.notes.testing") }}</div>
            </div>
            <div class="d-flex flex-row ml-4 align-items-center">
              <div class="mr-2 training-box"></div>
              <div>{{ $t("profile.notes.training") }}</div>
            </div>
          </div>
          <div class="tab-pane fade calendar-tab" :class="{ 'active show': isActive('all') }" id="all">
            <button class="notes-add-btn" @click="$bvModal.show('modal-register-mob')">
              <img width="38" src="/images/profile/plus-testing.svg">
            </button>
            <v-calendar
                :min-date="currentDate"
                :max-date="maxDate"
                class="custom-calendar max-w-full"
                :masks="masks"
                :attributes="attributes"
                disable-page-swipe
                is-expanded
            >
              <template v-slot:day-content="{ day, attributes }">
                <div class="flex flex-col h-full z-10 overflow-hidden day-content event-wrapper">
                  <span class="day-label">{{ day.day }}</span>
                  <div class="flex-grow event-day">
                    <div
                        style="cursor: pointer"
                        v-for="attr in attributes"
                        :key="attr.key"
                        class="text-xs leading-tight m-0"
                        :class="attr.customData.all.classStyle"
                        @click="dayClicked(attr)"
                    >
<!--                      <span class="day-label">{{ day.day }}</span>-->
                    </div>
                  </div>
                </div>
              </template>
            </v-calendar>
          </div>

          <div class="tab-pane fade calendar-tab" :class="{ 'active show': isActive('consultations') }"
               id="consultations">
            <button class="notes-add-btn" @click="$bvModal.show('modal-register-mob')">
              <img width="38" src="/images/profile/plus-testing.svg">
            </button>
            <v-calendar
                :min-date="currentDate"
                :max-date="maxDate"
                class="custom-calendar max-w-full"
                :masks="masks"
                :attributes="attributes"
                disable-page-swipe
                is-expanded
            >
              <template v-slot:day-content="{ day, attributes }">
                <div class="flex flex-col h-full z-10 overflow-hidden day-content event-wrapper">
                  <span class="day-label">{{ day.day }}</span>
                  <div class="flex-grow event-day">
                    <div
                        style="cursor: pointer"
                        v-for="attr in attributes"
                        :key="attr.key"
                        class="text-xs leading-tight m-0"
                        :class="attr.customData.consultations.classStyle"
                        @click="dayClicked(attr)"
                    >
                      <span class="day-label">{{ day.day }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </v-calendar>
          </div>

          <div class="tab-pane fade calendar-tab" :class="{ 'active show': isActive('testing') }" id="testing">
            <button class="notes-add-btn" @click="$bvModal.show('modal-register-mob')">
              <img width="38" src="/images/profile/plus-testing.svg">
            </button>
            <v-calendar

                :min-date="currentDate"
                :max-date="maxDate"
                class="custom-calendar max-w-full"
                :masks="masks"
                :attributes="attributes"
                disable-page-swipe
                is-expanded
            >
              <template v-slot:day-content="{ day, attributes }">
                <div class="flex flex-col h-full z-10 overflow-hidden day-content event-wrapper">
                  <span class="day-label">{{ day.day }}</span>
                  <div class="flex-grow event-day">
                    <div
                        style="cursor: pointer"
                        v-for="attr in attributes"
                        :key="attr.key"
                        class="text-xs leading-tight m-0"
                        :class="attr.customData.testing.classStyle"
                        @click="dayClicked(attr)"
                    >
                      <span class="day-label">{{ day.day }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </v-calendar>
          </div>

          <div class="tab-pane fade calendar-tab" :class="{ 'active show': isActive('training') }" id="training">
            <button class="notes-add-btn" @click="$bvModal.show('modal-register-mob')">
              <img width="38" src="/images/profile/plus-testing.svg">
            </button>
            <v-calendar
                :min-date="currentDate"
                :max-date="maxDate"
                class="custom-calendar max-w-full"
                :masks="masks"
                :attributes="attributes"
                disable-page-swipe
                is-expanded
            >
              <template v-slot:day-content="{ day, attributes }">
                <div class="flex flex-col h-full z-10 overflow-hidden day-content event-wrapper">
                  <span class="day-label">{{ day.day }}</span>
                  <div class="flex-grow event-day">
                    <div
                        style="cursor: pointer"
                        v-for="attr in attributes"
                        :key="attr.key"
                        class="text-xs leading-tight m-0"
                        :class="attr.customData.testing.classStyle"
                        @click="dayClicked(attr)"
                    >
                      <span class="day-label">{{ day.day }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </v-calendar>
          </div>
        </div>
      </div>
      <div class="register-buttons">
        <button class="d-flex flex-row align-items-center mt-2 mb-3 pl-0 register-button"
                @click="registerQab()">
          <img width="38" src="/images/profile/plus-consultation.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-consultation-qabilet") }}</div>
        </button>
        <button class="d-flex flex-row align-items-center mt-2 mb-3 pl-0 register-button"
                @click="openModalpsych()">
          <img width="38" src="/images/profile/plus-consultation.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-consultation") }}</div>
        </button>
        <button class="d-flex flex-row align-items-center pl-0 register-button mb-4"
                @click="showModalTest()">
          <img width="38" src="/images/profile/plus-testing.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-testing") }}</div>
        </button>
        <button class="d-flex flex-row align-items-center pl-0 register-button mb-4"
                @click="showModalTraining()">
          <img width="38" src="/images/profile/plus-training.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-training") }}</div>
        </button>
        <button v-if="is_special" class="d-flex flex-row align-items-center pl-0 register-button mb-4"
                @click="showModalSpecialTest()">
          <img width="38" src="/images/profile/plus-testing.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-special-testing") }}</div>
        </button>
      </div>
      <b-modal id="modal-register-for-consultation" class="modal-register" hide-footer hide-header size="lg">
        <button class="btn-close-top" @click="$bvModal.hide('modal-register-for-consultation')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <form class="register-modal row">
          <div class="testing-offline-title col-lg-12">
            {{ $t('profile.notes.modal-title') }}
          </div>

          <div class="select-type-mob">
            <el-radio-group v-model="select_type" class="w-100">
              <el-radio-button label="select-type-1">{{ $t('profile.notes.offline') }}</el-radio-button>
            </el-radio-group>
            <div class="select-type-mob-desc">
              {{ $t('profile.notes.offline-desc') }}
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-12 modal-register-col pl-3 pr-3">
            <div class="select-type">
              <div class="time-check">
                <label class="time-check-label" for="offline">
                  <input class="time-check-input" type="radio" name="off-on-line" id="offline" value="1" checked>
                  <div class="time-check-block">
                    <span class="time-checkmark"></span>
                    <span class="time-check-label-title">{{ $t('profile.notes.offline') }}</span>
                  </div>
                </label>
              </div>
            </div>
            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.specialist-label') }}</label>
              <el-select :placeholder="this.psych_name" disabled :value="this.psych_name">
              </el-select>
            </div>
            <div class="mb-3">
              {{ $t('profile.notes.select-date') }}
            </div>
            <div>
              <v-date-picker
                  v-model="psychDateConsultate"
                  class="calendar-test-date"
                  :model-config="modelConfig"
                  :attributes='attrs'
                  :min-date="currentDate"
                  :max-date="maxDate"
              />
              <button class="select-test-day btn-blue" @click="selectConsultationDate" type="button">
                {{ $t('profile.notes.btn-select') }}
              </button>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-12 modal-register-col pt-4 pt-lg-0 pl-3 pr-3">
            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('references.school-label') }}</label>

              <el-select
                  v-if="this.school_name.ru"
                  :value="this.$i18n.locale == 'ru'? this.school_name.ru : this.school_name.kz "
                  disabled>
              </el-select>
            </div>

            <div class="mb-4">
              {{ $t('profile.notes.time') }}
            </div>
            <div class="time-check-block d-flex flex-row mb-3">
              <div class="mr-3">
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-9">
                    <input @click="selecthour('09')" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-9" value="09" :disabled="this.psych_consultate_hours.hours9">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">09:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-10">
                    <input @click="selecthour(10)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-10" value="10" :disabled="this.psych_consultate_hours.hours10">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">10:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-11">
                    <input @click="selecthour(11)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-11" value="11" :disabled="this.psych_consultate_hours.hours11">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">11:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-12">
                    <input @click="selecthour(12)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-12" value="12" :disabled="this.psych_consultate_hours.hours12">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">12:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-13">
                    <input @click="selecthour(13)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-13" value="13" :disabled="this.psych_consultate_hours.hours13">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">13:00</span>
                    </div>
                  </label>
                </div>
              </div>
              <div class="ml-5">
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-14">
                    <input @click="selecthour(14)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-14" value="14" :disabled="this.psych_consultate_hours.hours14">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">14:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-15">
                    <input @click="selecthour(15)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-15" value="15" :disabled="this.psych_consultate_hours.hours15">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">15:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-16">
                    <input @click="selecthour(16)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-16" value="16" :disabled="this.psych_consultate_hours.hours16">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">16:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-17">
                    <input @click="selecthour(17)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-17" value="17" :disabled="this.psych_consultate_hours.hours17">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">17:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="time-consultation-18">
                    <input @click="selecthour(18)" class="time-check-input" type="radio" name="time-consultation"
                           id="time-consultation-18" value="18" :disabled="this.psych_consultate_hours.hours18">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">18:00</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-3">
              {{ $t('profile.notes.visit-purpose-label') }}
            </div>
            <div>
              <b-form-textarea
                  id="textarea-no-resize"
                  size="sm"
                  :placeholder="$t('profile.notes.visit-purpose-placeholder')"
                  rows="8"
                  no-resize
                  v-model="consultations.visit_purpose"
              ></b-form-textarea>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <b-button class="btn btn-register mt-4 btn-blue"
                      @click="submitPsych()">
              {{ $t('profile.notes.btn-send') }}
            </b-button>
            <b-button class="btn btn-register btn-register--mob mt-4 btn-white ml-3"
                      @click="$bvModal.hide('modal-register'); $bvModal.hide('modal-register-mob')">
              {{ $t('profile.notes.cancel-btn-2') }}
            </b-button>
          </div>
        </form>
      </b-modal>


      <b-modal id="modal-register-for-consultation-qabilet" class="modal-register" hide-footer hide-header size="lg">
        <button class="btn-close-top" @click="$bvModal.hide('modal-register-for-consultation-qabilet')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <form class="register-modal row">
          <div class="testing-offline-title col-lg-12">
            {{ $t('profile.notes.modal-title') }}
          </div>

          <div class="select-type-mob">
            <el-radio-group class="w-100">
              <el-radio-button label="select-type-1">{{ $t('profile.notes.offline') }}</el-radio-button>
            </el-radio-group>
            <div class="select-type-mob-desc">
              {{ $t('profile.notes.offline-desc') }}
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-12 modal-register-col pl-3 pr-3">
            <div class="select-type">
              <div class="time-check">
                <label class="time-check-label" for="offline">
                  <input class="time-check-input" @click="QabiletPsychForm.status=0" type="radio" name="off-on-line"
                         id="offline" value="1" checked>
                  <div class="time-check-block">
                    <span class="time-checkmark"></span>
                    <span class="time-check-label-title">{{ $t('profile.notes.offline') }}</span>
                  </div>
                </label>
              </div>
              <div class="time-check ml-5 d-none">
                <label class="time-check-label" for="online">
                  <input @click="QabiletPsychForm.status=1" class="time-check-input" type="radio" name="off-on-line"
                         id="online" value="2">
                  <div class="time-check-block">
                    <span class="time-checkmark"></span>
                    <span class="time-check-label-title">{{ $t('profile.notes.online') }}</span>
                  </div>
                </label>
              </div>
            </div>
            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.address-label') }}</label>
              <el-select v-model="QabiletPsychForm.adressQabilet"
                         disabled
                         @change="getQabiletPsychologs()"
                         :placeholder="$t('profile.notes.address-placeholder')">
                <el-option
                    v-for="item in this.regions"
                    :key="item.id"
                    :label="item.region_center_name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.specialist-label') }}</label>
              <el-select :disabled="!QabiletPsychForm.adressQabilet" v-model="QabiletPsychForm.psych_id"
                         :placeholder="$t('profile.notes.specialist-placeholder')">
                <el-option
                    v-for="item in this.QabiletPsychologs"
                    :key="item.psychologist.id"
                    :label="item.psychologist.name"
                    :value="item.psychologist.id">
                </el-option>
              </el-select>
            </div>
            <div class="mb-3">
              {{ $t('profile.notes.select-date') }}
            </div>
            <div>
              <v-date-picker
                  v-model="psychDateConsultateQabilet"
                  class="calendar-test-date"
                  :model-config="modelConfig"
                  :attributes='attrs'
                  :min-date="currentDate"
                  :max-date="maxDate"
              />
              <button style="top: 614px!important;" @click="selectConsultationDateQabilet()"
                      :disabled="!QabiletPsychForm.psych_id"
                      class="select-test-day btn-blue" type="button">{{ $t('profile.notes.btn-select') }}
              </button>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-12 modal-register-col pt-4 pt-lg-0 pl-3 pr-3">
            <div>{{ $t('profile.notes.select-lang') }}</div>
            <el-radio-group style="margin-top: 0.5rem;margin-bottom: 0.5rem" class="w-100" v-model="locale">
              <span @click="changeLocale('ru')"><el-radio-button
                  label="ru">{{ $t('profile.notes.ru') }}</el-radio-button></span>
              <span @click="changeLocale('kz')"><el-radio-button
                  label="kz">{{ $t('profile.notes.kz') }}</el-radio-button></span>
            </el-radio-group>

            <div class="mb-4">
              {{ $t('profile.notes.time') }}
            </div>
            <div class="time-check-block d-flex flex-row mb-3">
              <div class="mr-3">
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-9">
                    <input @click="selecthourQabilet('09')" class="time-check-input" type="radio"
                           name="time-consultation"
                           id="qabilet-time-consultation-9" value="9"
                           :disabled="this.qabilet_psych_consultate_hours.hours9">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">09:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-10">
                    <input @click="selecthourQabilet(10)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-10" value="10"
                           :disabled="this.qabilet_psych_consultate_hours.hours10">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">10:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-11">
                    <input @click="selecthourQabilet(11)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-11" value="11"
                           :disabled="this.qabilet_psych_consultate_hours.hours11">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">11:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-12">
                    <input @click="selecthourQabilet(12)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-12" value="12"
                           :disabled="this.qabilet_psych_consultate_hours.hours12">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">12:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-13">
                    <input @click="selecthourQabilet(13)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-13" value="13"
                           :disabled="this.qabilet_psych_consultate_hours.hours13">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">13:00</span>
                    </div>
                  </label>
                </div>
              </div>
              <div class="ml-5">
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-14">
                    <input @click="selecthourQabilet(14)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-14" value="14"
                           :disabled="this.qabilet_psych_consultate_hours.hours14">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">14:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-15">
                    <input @click="selecthourQabilet(15)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-15" value="15"
                           :disabled="this.qabilet_psych_consultate_hours.hours15">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">15:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-16">
                    <input @click="selecthourQabilet(16)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-16" value="16"
                           :disabled="this.qabilet_psych_consultate_hours.hours16">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">16:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-17">
                    <input @click="selecthourQabilet(17)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-17" value="17"
                           :disabled="this.qabilet_psych_consultate_hours.hours17">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">17:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="qabilet-time-consultation-18">
                    <input @click="selecthourQabilet(18)" class="time-check-input" type="radio" name="time-consultation"
                           id="qabilet-time-consultation-18" value="18"
                           :disabled="this.qabilet_psych_consultate_hours.hours18">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">18:00</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div class="mb-3">
              {{ $t('profile.notes.visit-purpose-label') }}
            </div>
            <div>
              <b-form-textarea
                  v-model="QabiletPsychForm.visit_purpose"
                  id="textarea-no-resize"
                  size="sm"
                  :placeholder="$t('profile.notes.visit-purpose-placeholder')"
                  rows="8"
                  no-resize
              ></b-form-textarea>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <b-button class="btn btn-register mt-4 btn-blue"
                      @click="submitPsychQabilet()">
              {{ $t('profile.notes.btn-send') }}
            </b-button>
            <b-button class="btn btn-register btn-register--mob mt-4 btn-white ml-3"
                      @click="$bvModal.hide('modal-register'); $bvModal.hide('modal-register-mob')">
              {{ $t('profile.notes.cancel-btn-2') }}
            </b-button>
          </div>
        </form>
      </b-modal>
      <b-modal id="modal-register" class="modal-register" hide-footer hide-header size="lg">
        <button class="btn-close-top" @click="$bvModal.hide('modal-register')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <form class="register-modal row">
          <div class="testing-offline-title col-lg-12">
            {{ $t('profile.notes.modal-title') }}
          </div>


          <div class="col-lg-6 col-md-12 col-12 modal-register-col pl-3 pr-3">
            <div class="mb-3">
              {{
                special_modal == true ? $t('profile.notes.select-date-special-test') : $t('profile.notes.select-date-test')
              }}
            </div>
            <div>
              <v-date-picker
                  class="calendar-test-date"
                  :model-config="modelConfig"
                  :attributes='attrs'
                  :min-date="currentDate"
                  :max-date="maxDate"
                  v-model="testDate"
              />
              <button @click="selectConsultationDateTest()" class="select-test-day btn-test btn-blue" type="button">
                {{ $t('profile.notes.btn-select') }}
              </button>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-12 modal-register-col pt-4 pt-lg-0 pl-3 pr-3">

            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.address-placeholder') }}</label>
              <el-select disabled v-model="select_address" :placeholder="$t('profile.notes.address-placeholder')">
                <el-option
                    v-for="item in this.regions"
                    :key="item.id"
                    :label="item.region_center_name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div v-if="special_modal===true" class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.testing.select-test') }}</label>
              <el-select v-model="select_test" :placeholder="$t('profile.testing.select-test')">
                <el-option
                    v-for="item in this.specialTestsArray"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>

            <div class="mb-4">
              {{ $t('profile.notes.time') }}
            </div>
            <div class="time-check-block d-flex flex-row mb-3">
              <div class="mr-3">
                <div class="time-check">
                  <label class="time-check-label" for="test-time-9">
                    <input @click="selecthourTestFunc('09')" class="time-check-input" type="radio"
                           name="time-consultation" id="test-time-9"
                           value="0" :disabled="test_hours.hours9">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">09:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-10">
                    <input @click="selecthourTestFunc(10)" class="time-check-input" type="radio"
                           name="time-consultation" id="test-time-10"
                           value="1" :disabled="test_hours.hours10">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">10:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-11">
                    <input @click="selecthourTestFunc(11)" class="time-check-input" type="radio"
                           name="time-consultation" id="test-time-11"
                           :disabled="test_hours.hours11" value="2">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">11:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-12">
                    <input @click="selecthourTestFunc(12)" class="time-check-input" type="radio"
                           name="time-consultation" id="test-time-12"
                           :disabled="test_hours.hours12" value="3">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">12:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-13">
                    <input @click="selecthourTestFunc(13)" :disabled="test_hours.hours13" class="time-check-input"
                           type="radio" name="time-consultation" id="test-time-13"
                           value="4">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">13:00</span>
                    </div>
                  </label>
                </div>
              </div>
              <div class="ml-5">
                <div class="time-check">
                  <label class="time-check-label" for="test-time-14">
                    <input @click="selecthourTestFunc(14)" :disabled="test_hours.hours14" class="time-check-input"
                           type="radio" name="time-consultation" id="test-time-14"
                           value="5">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">14:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-15">
                    <input @click="selecthourTestFunc(15)" :disabled="test_hours.hours15" class="time-check-input"
                           type="radio" name="time-consultation" id="test-time-15"
                           value="6">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">15:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-16">
                    <input @click="selecthourTestFunc(16)" :disabled="test_hours.hours16" class="time-check-input"
                           type="radio" name="time-consultation" id="test-time-16"
                           value="7">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">16:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-17">
                    <input @click="selecthourTestFunc(17)" :disabled="test_hours.hours17" class="time-check-input"
                           type="radio" name="time-consultation" id="test-time-17"
                           value="8">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">17:00</span>
                    </div>
                  </label>
                </div>
                <div class="time-check">
                  <label class="time-check-label" for="test-time-18">
                    <input @click="selecthourTestFunc(18)" :disabled="test_hours.hours18" class="time-check-input"
                           type="radio" name="time-consultation" id="test-time-18"
                           value="9">
                    <div class="time-check-block">
                      <span class="time-checkmark"></span>
                      <span class="time-check-label-title">18:00</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div v-if="special_modal===false" class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.testing.select-test') }}</label>
              <el-select v-if="$i18n.locale=='ru'" v-model="select_test"
                         :placeholder="$t('profile.testing.select-test')">
                <el-option
                    v-for="item in this.testsArray"
                    :key="item.id"
                    :label="item.title_ru"
                    :value="item.id">
                </el-option>
              </el-select>
              <el-select v-if="$i18n.locale=='kz'" v-model="select_test"
                         :placeholder="$t('profile.testing.select-test')">
                <el-option
                    v-for="item in this.testsArray"
                    :key="item.id"
                    :label="item.title_kz"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="mb-3 d-none">
              {{ $t('profile.fio') }}
            </div>
            <div style="display: none">
              <div class="form-group d-flex flex-column d-none">
                <el-input
                    disabled
                    :value="this.testStudentName"
                    :placeholder="this.testStudentName"

                ></el-input>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <b-button class="btn btn-register mt-4 btn-blue"
                      @click="sendRequestTest()">
              {{ $t('profile.notes.btn-send') }}
            </b-button>
            <b-button class="btn btn-register btn-register--mob mt-4 btn-white ml-3"
                      @click="sendRequestTest()">
              {{ $t('profile.notes.cancel-btn-2') }}
            </b-button>
          </div>
        </form>
      </b-modal>


      <b-modal id="modal-register-training" class="modal-register" hide-footer hide-header size="lg">
        <button class="btn-close-top" @click="$bvModal.hide('modal-register-training')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>

        <div class="mb-3">
          {{
            $t('profile.notes.select-date-training')
          }}
        </div>
        <form class="register-modal row">

          <div class="col-lg-6 col-md-12 col-12 modal-register-col pl-3 pr-3">
            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.select-type-training') }}</label>
              <el-select v-model="select_training_type"
                         :placeholder="$t('profile.notes.select-type-training')"
                         @change="changeType"
              >
                <el-option
                    key="1"
                    :label="$t('profile.notes.select-type-training-school')"
                    value="school">
                </el-option>
                <el-option
                    key="2"
                    :label="$t('profile.notes.select-type-training-center')"
                    value="center">
                </el-option>
              </el-select>
            </div>

            <div v-if="select_training_type == 'center'" class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.select-center') }}</label>
              <el-select v-model="select_training_center"
                         :placeholder="$t('profile.notes.select-center')"
                         @change="getCentersPsychologists"
              >
                <el-option
                    v-for="item in listCenters"
                    :key="item.id"
                    :label="$i18n.locale=='ru' ? item.region_center_name_ru : item.region_center_name_kz"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>

            <div v-if="select_training_type == 'school'" class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.select-school') }}</label>
              <el-select v-model="select_training_center"
                         :placeholder="$t('profile.notes.select-school')"
                         @change="getSchoolsPsychologists"
              >
                <el-option
                    v-for="item in listSchools"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

<!--            <div v-if="select_training_type == 'school' && !!select_training_center"-->
<!--                 class="form-group d-flex flex-column">-->
<!--              <label class="form-label">Выберите психолога школы</label>-->
<!--              <el-select v-model="select_psychologist"-->
<!--                         placeholder="Выберите психолога школы">-->
<!--                <el-option-->
<!--                    v-for="item in listSchoolsPsychologists"-->
<!--                    :key="item.id"-->
<!--                    :label="item.name"-->
<!--                    :value="item.id"-->
<!--                >-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->

<!--            <div v-if="select_training_type == 'center' && !!select_training_center"-->
<!--                 class="form-group d-flex flex-column">-->
<!--              <label class="form-label">Выберите психолога центра</label>-->
<!--              <el-select v-model="select_psychologist"-->
<!--                         placeholder="Выберите психолога центра">-->
<!--                <el-option-->
<!--                    v-for="item in listCentersPsychologists"-->
<!--                    :key="item.id"-->
<!--                    :label="item.name"-->
<!--                    :value="item.id"-->
<!--                >-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->

            <div v-if="this.userAge >= 7 && this.userAge <= 9" class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.select-subject-training') }}</label>
              <el-select v-model="select_subject"
                         :placeholder="$t('profile.notes.select-subject-training')"
                         @change="changeSubject"
              >
                <el-option
                    key="1"
                    :label="$t('profile.notes.modal-training-subject1')"
                    value="25">
                </el-option>
                <el-option
                    key="2"
                    :label="$t('profile.notes.modal-training-subject2')"
                    value="26">
                </el-option>
                <el-option
                    key="3"
                    :label="$t('profile.notes.modal-training-subject3')"
                    value="27">
                </el-option>
              </el-select>
            </div>

            <div v-if="this.userAge == 10 || this.userAge == 11"  class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.select-subject-training') }}</label>
              <el-select v-model="select_subject"
                         :placeholder="$t('profile.notes.select-subject-training')"
                         @change="changeSubject"
              >
                <el-option
                    key="1"
                    :label="$t('profile.notes.modal-training-subject4')"
                    value="20">
                </el-option>
                <el-option
                    key="2"
                    :label="$t('profile.notes.modal-training-subject5')"
                    value="22">
                </el-option>
                <el-option
                    key="3"
                    :label="$t('profile.notes.modal-training-subject6')"
                    value="16">
                </el-option>
                <el-option
                    key="4"
                    :label="$t('profile.notes.modal-training-subject7')"
                    value="21">
                </el-option>
              </el-select>
            </div>

            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.select-training-language') }}</label>
              <el-select v-model="select_lang"
                         :placeholder="$t('profile.testing.select-test')"
                         @change="changeLang"
              >
                <el-option
                    key="1"
                    :label="$t('profile.notes.ru')"
                    value="ru">
                </el-option>
                <el-option
                    key="2"
                    :label="$t('profile.notes.kz')"
                    value="kz">
                </el-option>
              </el-select>
            </div>

          </div>

          <div class="col-lg-6 col-md-12 col-12 modal-register-col pt-4 pt-lg-0 pl-3 pr-3">

            <div class="form-group d-flex flex-column">
              <label class="form-label">{{ $t('profile.notes.select-thread') }}</label>
              <el-select :disabled="Boolean(abilityChooseThread)" v-model="select_thread" :placeholder="$t('profile.notes.select-thread')" @change="getSchedules">
                <el-option
                    v-for="item in this.listThreads"
                    :key="item.id"
                    :label="item.training"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>

            <div class="mb-4" v-if="Boolean(select_thread)">
              Время консультаций
            </div>
            <div class="time-check-block d-flex flex-row mb-3" v-if="Boolean(select_thread)">
              <div class="mr-3 time-schedules">
                <div class="time-check" v-for="item in listSchedules">
                  <label class="time-check-label">
                    <div class="time-check-block">
                      <span class="time-check-label-title">{{item.time}}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <b-button class="btn btn-register mt-4 btn-blue"
                      @click="sendRequestTraining()">
              {{ $t('profile.notes.btn-send') }}
            </b-button>
          </div>
        </form>
      </b-modal>


      <b-modal id="modal-qr" class="modal-qr" hide-footer hide-header centered>
        <button class="btn-close-top" @click="$bvModal.hide('modal-qr')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <div class="modal-qr-title">
          {{ $t("profile.notes.qr-title") }}
        </div>
        <img width="360" height="360" :src="this.modalInfo.qr_code" alt="qr-code" class="modal-qr-img">
        <div class="modal-qr-desc">
          <span class="font-weight-bold">{{ $t("profile.notes.attention") }}</span>
          {{ $t("profile.notes.attention-desc") }}
        </div>
        <button class="btn-blue mx-auto" @click="$bvModal.hide('modal-qr')">{{ $t('profile.notes.close-btn') }}</button>
      </b-modal>

      <b-modal id="modal-event-desc" class="modal-event-desc" size="sm" hide-footer hide-header centered>
        <button class="btn-close-top" @click="$bvModal.hide('modal-event-desc')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <div class="modal-event-desc-body text-left">
          <div class="d-flex flex-row align-items-center">
            <img width="24" height="18" src="/images/profile/event-dark-1.svg" alt="">
            <div class="modal-event-desc-date">
              {{ this.modalInfo.consultationDate.replace('-', '.').replace('-', '.') }}
            </div>
          </div>
          <div class="modal-event-desc-title">
            {{ this.modalInfo.consultComment }}
          </div>
          <div class="modal-event-desc-title">
            {{ this.modalInfo.test_name_ru }}
          </div>
          <div class="d-flex flex-row align-items-center text-left">
            <img width="20" src="/images/profile/clock.svg" alt="" class="pr-1">
            <div class="modal-event-desc-time">
              {{ this.modalInfo.consultHour }}
            </div>
          </div>
          <div class="modal-event-desc-name">
            {{ this.modalInfo.consultPsychName }}
          </div>
          <button class="delete-request btn-blue" @click="showDeleteRequest=!showDeleteRequest">
            {{ $t('profile.settings.cancel-request') }}
          </button>
          <div v-if="showDeleteRequest">
            <b-form-textarea
                style="margin-top: 1rem"
                id="textarea-no-resize"
                size="sm"
                :placeholder="$t('profile.settings.cancel-request-purpose')"
                rows="8"
                no-resize
                v-model="reason_for_cancellation"
            ></b-form-textarea>
            <button :disabled="!reason_for_cancellation" @click="deleteRequest(modalInfo.consultId)"
                    class="delete-request btn-blue">{{ $t('profile.settings.cancel-aprove') }}
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal id="modal-event-desc-training" class="modal-event-desc" size="sm" hide-footer hide-header centered>
        <button class="btn-close-top" @click="$bvModal.hide('modal-event-desc-training')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <div class="modal-event-desc-body text-left">
          <div class="d-flex flex-row align-items-center">
            <img width="24" height="18" src="/images/profile/event-dark-1.svg" alt="">
            <div class="modal-event-desc-date">
              {{ this.modalInfo.consultationDate.replace('-', '.').replace('-', '.') }}
            </div>
          </div>
          <div class="modal-event-desc-title">
            {{ this.modalInfo.consultComment }}
          </div>
          <div class="modal-event-desc-title">
            {{ this.modalInfo.test_name_ru }}
          </div>
          <div class="d-flex flex-row align-items-center text-left">
            <img width="20" src="/images/profile/clock.svg" alt="" class="pr-1">
            <div class="modal-event-desc-time">
              {{ this.modalInfo.consultHour }}
            </div>
          </div>
          <div class="modal-event-desc-name">
            {{ this.modalInfo.consultPsychName }}
          </div>
        </div>
      </b-modal>

      <b-modal id="modal-event-desc-test" class="modal-event-desc" size="sm" hide-footer hide-header centered>
        <button class="btn-close-top" @click="$bvModal.hide('modal-event-desc-test')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <div class="modal-event-desc-body text-left">
          <div class="d-flex flex-row align-items-center">
            <img width="24" height="18" src="/images/profile/event-dark-1.svg" alt="">
            <div class="modal-event-desc-date">
              {{ this.modalInfo.request_date.replace('-', '.').replace('-', '.') }}
            </div>
          </div>
          <div v-if="this.$i18n.locale === 'ru'" class="modal-event-desc-title">
            {{ this.modalInfo.test_name_ru }}
          </div>
          <div v-else class="modal-event-desc-title">
            {{ this.modalInfo.test_name_kz }}
          </div>
          <div class="d-flex flex-row align-items-center text-left">
            <img width="20" src="/images/profile/clock.svg" alt="" class="pr-1">
            <div class="modal-event-desc-time">
              {{ this.modalInfo.request_time.substring(0, this.modalInfo.request_time.length - 3) }}
            </div>
          </div>

          <div v-if="this.$i18n.locale === 'ru'" class="modal-event-desc-name">
            {{ this.modalInfo.request_centre_ru }}
          </div>
          <div v-else class="modal-event-desc-name">
            {{ this.modalInfo.request_centre_kz }}
          </div>
          <button v-if="this.modalInfo.qr_code!=null" class="delete-request btn-blue" @click="modalQr()">
            {{ $t("profile.notes.openQr") }}
          </button>
          <button class="delete-request btn-blue" @click="showDeleteRequest=!showDeleteRequest">
            {{ $t('profile.settings.cancel-request') }}
          </button>
          <div v-if="showDeleteRequest">
            <b-form-textarea
                style="margin-top: 1rem"
                id="textarea-no-resize"
                size="sm"
                :placeholder="$t('profile.settings.cancel-request-purpose')"
                rows="8"
                no-resize
                v-model="reason_for_cancellation"
            ></b-form-textarea>
            <button :disabled="!reason_for_cancellation" @click="deleteRequestTest(modalInfo.request_id)"
                    class="delete-request btn-blue">{{ $t('profile.settings.cancel-aprove') }}
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal id="modal-register-mob" class="modal-register-mob" hide-footer hide-header centered>
        <button class="btn-close-top" @click="$bvModal.hide('modal-register-mob')">
          <img width="12" src="/images/profile/close.svg" alt="">
        </button>
        <button style="width: 90%;" class="d-flex flex-row align-items-center mt-2 mb-3 pl-0 register-button"
                @click="registerQab()">
          <img width="38" src="/images/profile/plus-consultation.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-consultation-qabilet") }}</div>
        </button>
        <button class="d-flex flex-row align-items-center mt-2 mb-3 pl-0 register-button"
                @click="openModalpsych()">
          <img width="38" src="/images/profile/plus-consultation.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-consultation") }}</div>
        </button>
        <button class="d-flex flex-row align-items-center pl-0 register-button mb-4"
                @click="showModalTest()">
          <img width="38" src="/images/profile/plus-testing.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-testing") }}</div>
        </button>
        <button class="d-flex flex-row align-items-center pl-0 register-button mb-4"
                @click="showModalTraining()">
          <img width="38" src="/images/profile/plus-training.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-training") }}</div>
        </button>
        <button v-if="is_special" class="d-flex flex-row align-items-center pl-0 register-button mb-4"
                @click="showModalSpecialTest()">
          <img width="38" src="/images/profile/plus-testing.svg" class="mr-3">
          <div class="text-left">{{ $t("profile.notes.register-for-special-testing") }}</div>
        </button>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import VCalendar from 'v-calendar';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment';
import VueMoment from "vue-moment";

export default {
  name: 'Notes',
  components: {
    ProfileNavbar,
  },
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      userAge: '',
      listCentersPsychologists: [],
      listSchoolsPsychologists: [],
      listCenters: [],
      listSchools: [],
      listThreads: [],
      listSchedules: [],
      disabledThreadsList: true,
      select_psychologist: '',
      select_training_center: '',
      select_training_type: '',
      select_subject: '',
      select_lang: '',
      select_thread: '',
      defaultCentre: '',
      qrImage: '',
      locale: localStorage.getItem('locale'),
      specialTestsArray: '',
      gender: '1',
      new_try_days_remaining: '',
      tries_per_month: '',
      is_special: false,
      special_modal: false,
      maxDate: new Date(),
      currentDate: new Date(),
      can_create_request: true,
      testTimeAray: '',
      testDate: '',
      testsArray: [],
      testStudentName: '',
      QabiletPsychologs: [],
      regionsLoading: false,
      regions: [],
      descriptionErrorTest: '',
      submitPsychIsDisabled: true,
      psych_busy_time: '',
      showDeleteRequest: false,
      psychDateConsultate: '',
      psychDateConsultateQabilet: '',
      psychDateConsultateQabiletbusy: '',
      psych_name: '',
      psych_id: '',
      school_name: '',
      select_address: '',
      select_specialist: '',
      select_test: '',
      select_type: '',
      select_test_hour: '',
      full_name: '',
      reason_for_cancellation: '',
      user_requests_history: '',
      psych_consultate_hours: {
        hours9: true,
        hours10: true,
        hours11: true,
        hours12: true,
        hours13: true,
        hours14: true,
        hours15: true,
        hours16: true,
        hours17: true,
        hours18: true,
      },
      test_hours: {
        hours9: true,
        hours10: true,
        hours11: true,
        hours12: true,
        hours13: true,
        hours14: true,
        hours15: true,
        hours16: true,
        hours17: true,
        hours18: true,
      },
      training_hours: {
        hours9: true,
        hours10: true,
        hours11: true,
        hours12: true,
        hours13: true,
        hours14: true,
        hours15: true,
        hours16: true,
        hours17: true,
        hours18: true,
      },
      qabilet_psych_consultate_hours: {
        hours9: true,
        hours10: true,
        hours11: true,
        hours12: true,
        hours13: true,
        hours14: true,
        hours15: true,
        hours16: true,
        hours17: true,
        hours18: true,
      },
      activeItem: 'all',
      masks: {
        weekdays: 'WWW',
      },
      modalInfo: {
        qr_code: '',
        test_name_ru: '',
        test_name_kz: '',
        request_time: '',
        request_id: '',
        request_date: '',
        request_centre_ru: '',
        request_centre_kz: '',
        consultationDate: '',
        consultComment: '',
        consultHour: '',
        consultPsychName: '',
        consultId: '',
      },
      attrs: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
      ],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      consultations: {
        user_id: '',
        visit_purpose: '',
        school_id: '',
        psych_id: '',
        date: '',
        selected_hour: '',

      },
      QabiletPsychForm: {
        adressQabilet: '',
        psych_id: '',
        date: '',
        hour: '',
        status: 0,
        visit_purpose: '',
      },
      attributes: [],
      currentDay: moment().format("D"),
      currentMonth: moment().locale('ru').format("MMMM"),
      currentYear: moment().format("YYYY"),
      currentWeekDay: moment().locale('ru').format('dddd')
    }
  },
  beforeCreate() {
    Vue.use(VCalendar),
        Vue.use(require('vue-moment'))

  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('user'))
    const iinUser = user.body.data.iin

    const userDate = iinUser.split('')
    const userDateYear = userDate[0] + userDate[1]
    const userDateYearFull = +userDateYear < new Date().getFullYear() ? +userDateYear + 2000 : +userDateYear + 1900
    const userDateAgeFull = new Date().getFullYear() - userDateYearFull
    const userDateMonth = +(userDate[2] + userDate[3])
    const userDateDay = +(userDate[4] + userDate[5])
    const currentMonth = new Date().getMonth() + 1
    const currentDay = new Date().getDate()
    this.userAge = userDateAgeFull

    if (userDateMonth === currentMonth) {
      if (userDateDay > currentDay) {
        this.userAge = userDateAgeFull - 1
      }
    } else if (userDateMonth > currentMonth) {
      this.userAge = userDateAgeFull - 1
    }
    this.getSchools()
    this.getCenters()
    this.updateInfo()
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.gender = res.data.data.sex;
        })
  },

  methods: {
    modalQr() {
      this.$bvModal.show('modal-qr');
    },
    changeLocale(locale) {
      this.locale = locale;
      this.QabiletPsychForm.psych_id = undefined;
      this.QabiletPsychForm.adressQabilet = this.defaultCentre;
      this.QabiletPsychologs = undefined;
      this.qabilet_psych_consultate_hours.hours9 = true;
      this.qabilet_psych_consultate_hours.hours10 = true;
      this.qabilet_psych_consultate_hours.hours11 = true;
      this.qabilet_psych_consultate_hours.hours12 = true;
      this.qabilet_psych_consultate_hours.hours13 = true;
      this.qabilet_psych_consultate_hours.hours14 = true;
      this.qabilet_psych_consultate_hours.hours15 = true;
      this.qabilet_psych_consultate_hours.hours16 = true;
      this.qabilet_psych_consultate_hours.hours17 = true;
      this.qabilet_psych_consultate_hours.hours18 = true;
      document.getElementById('qabilet-time-consultation-9').checked = false
      document.getElementById('qabilet-time-consultation-10').checked = false
      document.getElementById('qabilet-time-consultation-11').checked = false
      document.getElementById('qabilet-time-consultation-12').checked = false
      document.getElementById('qabilet-time-consultation-13').checked = false
      document.getElementById('qabilet-time-consultation-14').checked = false
      document.getElementById('qabilet-time-consultation-15').checked = false
      document.getElementById('qabilet-time-consultation-16').checked = false
      document.getElementById('qabilet-time-consultation-17').checked = false
      document.getElementById('qabilet-time-consultation-18').checked = false
      this.getQabiletPsychologs()

    },
    openModalpsych() {
      if (this.psych_name != false) {
        this.test_hours.hours9 = true
        this.test_hours.hours10 = true
        this.test_hours.hours11 = true
        this.test_hours.hours12 = true
        this.test_hours.hours13 = true
        this.test_hours.hours14 = true
        this.test_hours.hours15 = true
        this.test_hours.hours16 = true
        this.test_hours.hours17 = true
        this.test_hours.hours18 = true
        if (this.can_create_request == true) {
          this.updateInfo()
          this.$bvModal.show('modal-register-for-consultation');
        } else {
          if (this.gender == 1) {
            Vue.toastr({
              message: this.$t('profile.settings.error'),
              description: this.$t('profile.settings.dear-user-male') + ' ' + this.testStudentName + ' ' + this.$t('profile.settings.request-tes-eror'),
              type: 'error'
            });
          }
          if (this.gender == 2) {
            this.descriptionErrorTest =
                Vue.toastr({
                  message: this.$t('profile.settings.error'),
                  description: this.$t('profile.settings.dear-user-female') + ' ' + this.testStudentName + ' ' + this.$t('profile.settings.request-tes-eror'),
                  type: 'error'
                });
          }

        }
      } else {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.you-cant-create-request-psych'),
          type: 'error'
        });
      }
    },
    showModalSpecialTest() {
      this.test_hours.hours9 = true,
          this.test_hours.hours10 = true,
          this.test_hours.hours11 = true,
          this.test_hours.hours12 = true,
          this.test_hours.hours13 = true,
          this.test_hours.hours14 = true,
          this.test_hours.hours15 = true,
          this.test_hours.hours16 = true,
          this.test_hours.hours17 = true,
          this.test_hours.hours18 = true,

          this.updateInfo()
      this.special_modal = true
      this.$bvModal.show('modal-register')
    },
    showModalTest() {
      this.test_hours.hours9 = true,
          this.test_hours.hours10 = true,
          this.test_hours.hours11 = true,
          this.test_hours.hours12 = true,
          this.test_hours.hours13 = true,
          this.test_hours.hours14 = true,
          this.test_hours.hours15 = true,
          this.test_hours.hours16 = true,
          this.test_hours.hours17 = true,
          this.test_hours.hours18 = true,
          this.updateInfo()
      this.special_modal = false
      this.$bvModal.show('modal-register')
    },
    showModalTraining() {
      this.training_hours.hours9 = true,
          this.training_hours.hours10 = true,
          this.training_hours.hours11 = true,
          this.training_hours.hours12 = true,
          this.training_hours.hours13 = true,
          this.training_hours.hours14 = true,
          this.training_hours.hours15 = true,
          this.training_hours.hours16 = true,
          this.training_hours.hours17 = true,
          this.training_hours.hours18 = true,
          this.updateInfo()
      this.special_modal = false
      this.$bvModal.show('modal-register-training')
    },
    registerQab() {
      this.qabilet_psych_consultate_hours.hours9 = true;
      this.qabilet_psych_consultate_hours.hours10 = true;
      this.qabilet_psych_consultate_hours.hours11 = true;
      this.qabilet_psych_consultate_hours.hours12 = true;
      this.qabilet_psych_consultate_hours.hours13 = true;
      this.qabilet_psych_consultate_hours.hours14 = true;
      this.qabilet_psych_consultate_hours.hours15 = true;
      this.qabilet_psych_consultate_hours.hours16 = true;
      this.qabilet_psych_consultate_hours.hours17 = true;
      this.qabilet_psych_consultate_hours.hours18 = true;
      if (this.can_create_request == true) {
        this.updateInfo()
        this.$bvModal.show('modal-register-for-consultation-qabilet')
      } else {
        if (this.gender == 1) {
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: this.$t('profile.settings.dear-user-male') + ' ' + this.testStudentName + ' ' + this.$t('profile.settings.request-tes-eror'),
            type: 'error'
          });
        }
        if (this.gender == 2) {
          this.descriptionErrorTest =
              Vue.toastr({
                message: this.$t('profile.settings.error'),
                description: this.$t('profile.settings.dear-user-female') + ' ' + this.testStudentName + ' ' + this.$t('profile.settings.request-tes-eror'),
                type: 'error'
              });
        }
      }
    },

    getTest() {
      this.$http.post(window.API_ROOT + '/api/child/test_request?status=1')
          .then((res) => {

          });

    },

    getCenters() {
      this.$http.get(window.API_ROOT + '/api/references/regional-centers')
          .then((res) => {
            this.listCenters = res.body.data
          });
    },

    getSchools() {
      const user = JSON.parse(localStorage.getItem('user'))
      const city_id = user.body.data.education.city_id

      this.$http.get(window.API_ROOT + `/api/references/schools/by-city/${city_id}`)
          .then((res) => {
            this.listSchools = res.body.data
          });
    },

    changeType() {
      this.select_training_center = ''
      this.select_psychologist = ''
      this.select_thread = ''
      this.listThreads = []
    },

    changeSubject() {
      this.select_thread = ''
      this.listThreads = []
    },

    changeLang() {
      this.select_thread = ''
      this.listThreads = []
    },

    getSchoolsPsychologists(id) {
      this.select_thread = ''
      this.listThreads = []

      this.$http.get(window.API_ROOT + `/api/references/psychologists/school/${id}`)
          .then((res) => {
            const schoolsPsychologists = Object.entries(res.body)
            const schoolsPsychologistsArr = []

            schoolsPsychologists.forEach(element => {
              let str = element.join('')
              const id = str.replace(/[^0-9]/g, "")
              const name = str.replace(/[^А-яЁё A-Za-z]/g, "")

              schoolsPsychologistsArr.push({id, name: name.trim()})

              this.listSchoolsPsychologists = schoolsPsychologistsArr
            })
          });
    },

    getCentersPsychologists(id) {
      this.$http.get(window.API_ROOT + `/api/references/psychologists/regional-center/${id}`)
          .then((res) => {
            const centersPsychologists = Object.entries(res.body)
            const centersPsychologistsArr = []

            centersPsychologists.forEach(element => {
              let str = element.join('')
              const id = str.replace(/[^0-9]/g, "")
              const name = str.replace(/[^А-яЁё A-Za-z]/g, "")

              centersPsychologistsArr.push({id, name: name.trim()})

              this.listCentersPsychologists = centersPsychologistsArr
            })
          });
    },

    getThreads() {
      this.$http.get(window.API_ROOT + `/api/trainings/list?owner_type=${this.select_training_type}&owner_id=${this.select_training_center}&language=${this.select_lang}&subject_id=${this.select_subject}`)
          .then((res) => {
            this.listThreads = res.body.data
            this.disabledThreadsList = false
          });
    },

    getSchedules(id) {
      this.$http.get(window.API_ROOT + `/api/trainings/schedules?thread_id=${id}`)
          .then((res) => {
            this.listSchedules = res.body.data
          });
    },

    selectConsultationDateTest() {
      document.getElementById('test-time-9').checked = false
      document.getElementById('test-time-10').checked = false
      document.getElementById('test-time-11').checked = false
      document.getElementById('test-time-12').checked = false
      document.getElementById('test-time-13').checked = false
      document.getElementById('test-time-14').checked = false
      document.getElementById('test-time-15').checked = false
      document.getElementById('test-time-16').checked = false
      document.getElementById('test-time-17').checked = false
      document.getElementById('test-time-18').checked = false
      this.test_hours.hours9 = true
      this.test_hours.hours10 = true
      this.test_hours.hours11 = true
      this.test_hours.hours12 = true
      this.test_hours.hours13 = true
      this.test_hours.hours14 = true
      this.test_hours.hours15 = true
      this.test_hours.hours16 = true
      this.test_hours.hours17 = true
      this.test_hours.hours18 = true

      if (this.special_modal == false) {
        var response = window.API_ROOT + '/api/create/test/request/time?date=' + this.testDate + '&regional_center_id=' + this.select_address
        this.$http.post(response)
            .then((res) => {
              this.testTimeAray = Object.values(res.body.data)
              for (var index = 0; index < this.testTimeAray.length; ++index) {
                if (this.testTimeAray[index].time == '09:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours10 = false
                    document.getElementById('test-time-9').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours10 = false
                    document.getElementById('test-time-9').checked = false
                  }
                }
                if (this.testTimeAray[index].time == '10:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours10 = false
                    document.getElementById('test-time-10').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours10 = false
                    document.getElementById('test-time-10').checked = false
                  }
                }
                if (this.testTimeAray[index].time == '11:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours11 = false
                    document.getElementById('test-time-11').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours11 = false
                    document.getElementById('test-time-11').checked = false
                  }

                }
                if (this.testTimeAray[index].time == '12:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours12 = false
                    document.getElementById('test-time-12').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours12 = false
                    document.getElementById('test-time-12').checked = false
                  }
                }
                if (this.testTimeAray[index].time == '13:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours13 = false
                    document.getElementById('test-time-13').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours13 = false
                    document.getElementById('test-time-13').checked = false
                  }
                }
                if (this.testTimeAray[index].time == '14:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours14 = false
                    document.getElementById('test-time-14').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours14 = false
                    document.getElementById('test-time-14').checked = false
                  }

                }
                if (this.testTimeAray[index].time == '15:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours15 = false
                    document.getElementById('test-time-15').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours15 = false
                    document.getElementById('test-time-15').checked = false
                  }
                }
                if (this.testTimeAray[index].time == '16:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours16 = false
                    document.getElementById('test-time-16').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours16 = false
                    document.getElementById('test-time-16').checked = false
                  }
                }
                if (this.testTimeAray[index].time == '17:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours17 = false
                    document.getElementById('test-time-17').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours17 = false
                    document.getElementById('test-time-17').checked = false
                  }

                }
                if (this.testTimeAray[index].time == '18:00:00') {
                  if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                    this.test_hours.hours18 = false
                    document.getElementById('test-time-18').checked = false
                  } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                    this.test_hours.hours18 = false
                    document.getElementById('test-time-18').checked = false
                  }

                }
              }
            });
      }
      if (this.special_modal == true) {
        if (this.select_test == false) {
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: this.$t('profile.test-first-error'),
            type: 'error'
          });
          return
        }
        if (this.testDate) {
          var response = window.API_ROOT + '/api/quiz-freetime?date=' + this.testDate + '&quiz_id=' + this.select_test
          this.$http.get(response)
              .then((res) => {
                this.testTimeAray = Object.values(res.body.data)
                for (var index = 0; index < this.testTimeAray.length; ++index) {
                  if (this.testTimeAray[index].time == '09:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours9 = false
                      document.getElementById('test-time-9').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours9 = false
                      document.getElementById('test-time-9').checked = false
                    }
                  }
                  if (this.testTimeAray[index].time == '10:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours10 = false
                      document.getElementById('test-time-10').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours10 = false
                      document.getElementById('test-time-10').checked = false
                    }
                  }
                  if (this.testTimeAray[index].time == '11:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours11 = false
                      document.getElementById('test-time-11').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours11 = false
                      document.getElementById('test-time-11').checked = false
                    }

                  }
                  if (this.testTimeAray[index].time == '12:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours12 = false
                      document.getElementById('test-time-12').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours12 = false
                      document.getElementById('test-time-12').checked = false
                    }
                  }
                  if (this.testTimeAray[index].time == '13:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours13 = false
                      document.getElementById('test-time-13').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours13 = false
                      document.getElementById('test-time-13').checked = false
                    }
                  }
                  if (this.testTimeAray[index].time == '14:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours14 = false
                      document.getElementById('test-time-14').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours14 = false
                      document.getElementById('test-time-14').checked = false
                    }

                  }
                  if (this.testTimeAray[index].time == '15:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours15 = false
                      document.getElementById('test-time-15').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours15 = false
                      document.getElementById('test-time-15').checked = false
                    }
                  }
                  if (this.testTimeAray[index].time == '16:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours16 = false
                      document.getElementById('test-time-16').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours16 = false
                      document.getElementById('test-time-16').checked = false
                    }
                  }
                  if (this.testTimeAray[index].time == '17:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours17 = false
                      document.getElementById('test-time-17').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours17 = false
                      document.getElementById('test-time-17').checked = false
                    }

                  }
                  if (this.testTimeAray[index].time == '18:00:00') {
                    if (this.testTimeAray[index].is_special == 1 & this.special_modal == true) {
                      this.test_hours.hours18 = false
                      document.getElementById('test-time-18').checked = false
                    } else if (this.special_modal == false & this.testTimeAray[index].is_special == 0) {
                      this.test_hours.hours18 = false
                      document.getElementById('test-time-18').checked = false
                    }

                  }
                }
              });
        }
      }


    },
    sendRequestTest() {
      if (this.testDate == false || this.select_test_hour == false || this.select_test == false || this.select_address == false) {

        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.all-data-complete'),
          type: 'error'
        });
      } else {
        let request;
        request = '/api/test_request?regional_centre_id=' + this.select_address + '&quiz_id=' + this.select_test + '&time=' + this.select_test_hour + '&date=' + this.testDate + '&status_id=1&type_id=1';
        if (this.special_modal == true) {
          request = request + '&is_special=1'
        }
        this.$http.post(window.API_ROOT + request)
            .then((res) => {
              Vue.toastr({
                message: this.$t('profile.settings.success') + '!',
                description: this.$t('profile.settings.register-success'),
                type: 'success'
              });
              this.updateInfo()
              this.$bvModal.hide('modal-register');
              this.test_hours.hours9 = true,
                  this.test_hours.hours10 = true,
                  this.test_hours.hours11 = true,
                  this.test_hours.hours12 = true,
                  this.test_hours.hours13 = true,
                  this.test_hours.hours14 = true,
                  this.test_hours.hours15 = true,
                  this.test_hours.hours16 = true,
                  this.test_hours.hours17 = true,
                  this.test_hours.hours18 = true,
                  document.getElementById('test-time-9').checked = false
              document.getElementById('test-time-10').checked = false
              document.getElementById('test-time-11').checked = false
              document.getElementById('test-time-12').checked = false
              document.getElementById('test-time-13').checked = false
              document.getElementById('test-time-14').checked = false
              document.getElementById('test-time-15').checked = false
              document.getElementById('test-time-16').checked = false
              document.getElementById('test-time-17').checked = false
              document.getElementById('test-time-18').checked = false
              this.testDate = '',
                  this.select_test_hour = '',
                  this.select_test = '',
                  this.select_address = ''
            }).catch((e) => {
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: res.body.message,
            type: 'error'
          });
          this.updateInfo()
          this.$bvModal.hide('modal-register');
        });

      }
    },

    sendRequestTraining() {

      if (!this.select_thread) {
        return Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.all-data-complete'),
          type: 'error'
        });
      }

      this.$http.post(window.API_ROOT + `/api/trainings/make-record`,{thread_id: this.select_thread})
          .then((res) => {
            Vue.toastr({
              message: this.$t('profile.settings.success') + '!',
              description: this.$t('profile.settings.register-success'),
              type: 'success'
            });
            this.updateInfo()
            this.$bvModal.hide('modal-register-training');
          }).catch((e) => {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: res.body.message,
          type: 'error'
        });
        this.updateInfo()
        this.$bvModal.hide('modal-register-training');
      });
    },

    getQabiletPsychologs() {
      this.$http.post(window.API_ROOT + '/api/create/request?regional_center_id=' + this.QabiletPsychForm.adressQabilet + '&lang=' + this.locale)
          .then((res) => {
            this.QabiletPsychForm.psych_id = ''
            this.QabiletPsychologs = res.body.psychologists
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push('/')
            }
          });

    },
    async getSurfaces() {
      try {
        this.regionsLoading = true;
        this.regions = [];
        const res = await this.$http.get(window.API_ROOT + '/api/references/regional-centers')
        if (res.data.data) {

          this.surfaces = res.data.data
          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i];
            this.regions.push({
              id: element.id,
              region_center_name: element[`region_center_name_${this.$i18n.locale}`]
            })

          }
          this.selectedRegion = res.data.data[4]
        }
      } catch (error) {
        Vue.toastr({
          message: '',
          description: `${error}`,
          type: 'error'
        });
      } finally {
        this.regionsLoading = false
      }
    },


    dayClicked(day) {
      this.showDeleteRequest = false,
          this.modalInfo.qr_code = day.customData.request_info.qr_code,
          this.modalInfo.test_name_ru = day.customData.request_info.request_test_ru,
          this.modalInfo.test_name_kz = day.customData.request_info.request_test_kz,
          this.modalInfo.request_centre_ru = day.customData.request_info.request_centre_ru,
          this.modalInfo.request_centre_kz = day.customData.request_info.request_centre_kz,
          this.modalInfo.request_date = day.customData.request_info.request_date.split('-').reverse().join('.'),
          this.modalInfo.request_id = day.customData.request_info.request_id,
          this.modalInfo.request_time = day.customData.request_info.request_time,
          this.modalInfo.consultationDate = day.customData.request_info.request_date.split('-').reverse().join('.'),
          this.modalInfo.consultComment = day.customData.request_info.request_visit_purpose,
          this.modalInfo.consultHour = day.customData.request_info.request_time.slice(0, -3),
          this.modalInfo.consultPsychName = day.customData.request_info.psycholog_name,
          this.modalInfo.consultId = day.customData.request_info.request_id
      if (day.customData.request_info.type == "test") {
        this.$bvModal.show('modal-event-desc-test');
      } else if (day.customData.request_info.type == "thread") {
        this.$bvModal.show('modal-event-desc-training');
      } else {
        this.$bvModal.show('modal-event-desc');
      }


    },

    updateInfo() {
      this.getSurfaces()
      this.attributes = []
      this.logged = !(localStorage.getItem('token') == '');
      this.$http.get(window.API_ROOT + '/api/create/test/request')
          .then((res) => {
            this.testStudentName = res.body.data.student_name.replace('null', '')
            this.testsArray = res.body.data.test
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push('/')
            }
          });
      this.$http.get(window.API_ROOT + '/api/special-quizzes')
          .then((res) => {
            this.specialTestsArray = res.body.data
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push('/')
            }
          });
      this.$http.get(window.API_ROOT + '/api/create/request?lang=' + this.locale)
          .then((res) => {
            this.defaultCentre = res.body.regional_center
            this.currentDate = res.body.datetime.substr(0, 10)
            this.currentDate = new Date(this.currentDate);
            this.maxDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 2, 0)
            this.is_special = res.body.special_is_available
            this.new_try_days_remaining = res.body.new_try_days_remaining
            if (this.new_try_days_remaining == null) {
              this.new_try_days_remaining = 1
            }
            this.tries_per_month = res.body.tries_per_month

            this.can_create_request = res.body.can_create_request
            this.psych_name = res.body.psychologist.name

            this.psych_id = res.body.psychologist.id
            this.school_name = res.body.school_info
            this.consultations.psych_id = res.body.psychologist.id
            this.psych_busy_time = res.body.date_time
            this.consultations.user_id = res.body.user_id
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push('/')
            }
          });
      this.$http.get(window.API_ROOT + '/api/request')
          .then((res) => {
            this.user_requests_history = res.body.data
            for (var i = 0; i < this.user_requests_history.length; i++) {

              let userRequest = {
                key: 1,
                customData: {
                  request_info: {
                    request_date: this.user_requests_history[i].date,
                    request_visit_purpose: this.user_requests_history[i].visit_purpose,
                    psycholog_name: this.user_requests_history[i].psychologist_name.name,
                    request_id: this.user_requests_history[i].id,
                    request_time: this.user_requests_history[i].time,
                  },
                  all: {
                    classStyle: 'consultation-bg',
                  },
                  consultations: {
                    classStyle: 'consultation-bg',
                  },
                  testing: {
                    classStyle: '',
                  },
                },
                dates: this.user_requests_history[i].date,


              }
              this.attributes.push(userRequest)


            }
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push('/')
            }
          });
      this.$http.post(window.API_ROOT + '/api/child/test_request?status=1')
          .then((res) => {
            this.user_requests_history = res.body.data
            for (var i = 0; i < this.user_requests_history.length; i++) {

              let userRequest = {

                key: 2,
                customData: {
                  request_info: {
                    type: 'test',
                    qr_code: this.user_requests_history[i].qr_code,
                    request_test_ru: this.user_requests_history[i].test.title_ru,
                    request_test_kz: this.user_requests_history[i].test.title_kz,
                    request_centre_ru: this.user_requests_history[i].regional_centre_id.region_center_name_ru,
                    request_centre_kz: this.user_requests_history[i].regional_centre_id.region_center_name_kz,
                    request_date: this.user_requests_history[i].date,
                    request_id: this.user_requests_history[i].id,
                    request_time: this.user_requests_history[i].time,
                  },
                  all: {
                    classStyle: 'testing-bg',
                  },
                  consultations: {
                    classStyle: '',
                  },
                  testing: {
                    classStyle: 'testing-bg',
                  },
                },
                dates: this.user_requests_history[i].date,


              }
              this.attributes.push(userRequest)


            }
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push('/')
            }
          });

      this.$http.get(window.API_ROOT + '/api/trainings/my-schedules')
          .then((res) => {
            this.user_requests_history = res.body.data
            for (let i = 0; i < this.user_requests_history.length; i++) {

              let userRequest = {
                key: 3,
                customData: {
                  request_info: {
                    type: 'thread',
                    request_date: this.user_requests_history[i].date,
                    request_test_ru: this.user_requests_history[i].title,
                    request_test_kz: this.user_requests_history[i].title,
                    request_time: this.user_requests_history[i].time,
                  },
                  all: {
                    classStyle: 'training-bg',
                  },
                  consultations: {
                    classStyle: '',
                  },
                  testing: {
                    classStyle: '',
                  },
                },
                dates: this.user_requests_history[i].date,


              }
              this.attributes.push(userRequest)

            }
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push('/')
            }
          });

      this.QabiletPsychForm.adressQabilet = this.defaultCentre;
      this.select_address = this.defaultCentre;
      this.getQabiletPsychologs()

    },
    selectConsultationDateQabilet() {
      this.qabilet_psych_consultate_hours.hours9 = true;
      this.qabilet_psych_consultate_hours.hours10 = true;
      this.qabilet_psych_consultate_hours.hours11 = true;
      this.qabilet_psych_consultate_hours.hours12 = true;
      this.qabilet_psych_consultate_hours.hours13 = true;
      this.qabilet_psych_consultate_hours.hours14 = true;
      this.qabilet_psych_consultate_hours.hours15 = true;
      this.qabilet_psych_consultate_hours.hours16 = true;
      this.qabilet_psych_consultate_hours.hours17 = true;
      this.qabilet_psych_consultate_hours.hours18 = true;
      document.getElementById('qabilet-time-consultation-10').checked = false
      document.getElementById('qabilet-time-consultation-11').checked = false
      document.getElementById('qabilet-time-consultation-12').checked = false
      document.getElementById('qabilet-time-consultation-13').checked = false
      document.getElementById('qabilet-time-consultation-14').checked = false
      document.getElementById('qabilet-time-consultation-15').checked = false
      document.getElementById('qabilet-time-consultation-16').checked = false
      document.getElementById('qabilet-time-consultation-17').checked = false
      document.getElementById('qabilet-time-consultation-18').checked = false

      if (this.psychDateConsultateQabilet) {
        this.$http.post(window.API_ROOT + '/api/create/request/date?psychologist_id=' + this.QabiletPsychForm.psych_id + '&date=' + this.psychDateConsultateQabilet)
            .then((res) => {
              this.psychDateConsultateQabiletbusy = Object.values(res.body)

              var psych_busy_tyme_aray = this.psychDateConsultateQabiletbusy

              for (var i = 0; i < psych_busy_tyme_aray.length; i++) {
                if (psych_busy_tyme_aray[i] == '09:00:00') {
                  this.qabilet_psych_consultate_hours.hours9 = false;
                }
                if (psych_busy_tyme_aray[i] == '10:00:00') {
                  this.qabilet_psych_consultate_hours.hours10 = false;
                }
                if (psych_busy_tyme_aray[i] == '11:00:00') {
                  this.qabilet_psych_consultate_hours.hours11 = false;
                }
                if (psych_busy_tyme_aray[i] == '12:00:00') {
                  this.qabilet_psych_consultate_hours.hours12 = false;
                }
                if (psych_busy_tyme_aray[i] == '13:00:00') {
                  this.qabilet_psych_consultate_hours.hours13 = false;
                }
                if (psych_busy_tyme_aray[i] == '14:00:00') {
                  this.qabilet_psych_consultate_hours.hours14 = false;
                }
                if (psych_busy_tyme_aray[i] == '15:00:00') {
                  this.qabilet_psych_consultate_hours.hours15 = false;
                }
                if (psych_busy_tyme_aray[i] == '16:00:00') {
                  this.qabilet_psych_consultate_hours.hours16 = false;
                }
                if (psych_busy_tyme_aray[i] == '17:00:00') {
                  this.qabilet_psych_consultate_hours.hours17 = false;
                }
                if (psych_busy_tyme_aray[i] == '18:00:00') {
                  this.qabilet_psych_consultate_hours.hours18 = false;
                }
              }

            })
            .catch((e) => {
              if (e.status === 401) {
                this.$router.push('/')
              }
            });
      }
    },

    selectConsultationDate: function () {
      this.updateInfo();
      if (this.psychDateConsultate) {
        document.getElementById('time-consultation-9').checked = false
        document.getElementById('time-consultation-10').checked = false
        document.getElementById('time-consultation-11').checked = false
        document.getElementById('time-consultation-12').checked = false
        document.getElementById('time-consultation-13').checked = false
        document.getElementById('time-consultation-14').checked = false
        document.getElementById('time-consultation-15').checked = false
        document.getElementById('time-consultation-16').checked = false
        document.getElementById('time-consultation-17').checked = false
        document.getElementById('time-consultation-18').checked = false
        this.psych_consultate_hours.hours9 = true;
        this.psych_consultate_hours.hours10 = true;
        this.psych_consultate_hours.hours11 = true;
        this.psych_consultate_hours.hours12 = true;
        this.psych_consultate_hours.hours13 = true;
        this.psych_consultate_hours.hours14 = true;
        this.psych_consultate_hours.hours15 = true;
        this.psych_consultate_hours.hours16 = true;
        this.psych_consultate_hours.hours17 = true;
        this.psych_consultate_hours.hours18 = true;
        this.$http.post(window.API_ROOT + '/api/create/request/date?psychologist_id=' + this.psych_id + '&date=' + this.psychDateConsultate)
            .then((res) => {

              var psych_busy_tyme_aray = Object.values(res.body)
              for (var i = 0; i < psych_busy_tyme_aray.length; i++) {
                if (psych_busy_tyme_aray[i] == '09:00:00') {
                  this.psych_consultate_hours.hours9 = false;
                  document.getElementById('time-consultation-9').checked = false
                }
                if (psych_busy_tyme_aray[i] == '10:00:00') {
                  this.psych_consultate_hours.hours10 = false;
                  document.getElementById('time-consultation-10').checked = false
                }

                if (psych_busy_tyme_aray[i] == '11:00:00') {
                  this.psych_consultate_hours.hours11 = false;
                  document.getElementById('time-consultation-11').checked = false
                }
                if (psych_busy_tyme_aray[i] == '12:00:00') {
                  this.psych_consultate_hours.hours12 = false;
                  document.getElementById('time-consultation-12').checked = false
                }
                if (psych_busy_tyme_aray[i] == '13:00:00') {
                  this.psych_consultate_hours.hours13 = false;
                  document.getElementById('time-consultation-13').checked = false
                }
                if (psych_busy_tyme_aray[i] == '14:00:00') {
                  this.psych_consultate_hours.hours14 = false;
                  document.getElementById('time-consultation-14').checked = false
                }
                if (psych_busy_tyme_aray[i] == '15:00:00') {
                  this.psych_consultate_hours.hours15 = false;
                  document.getElementById('time-consultation-15').checked = false
                }
                if (psych_busy_tyme_aray[i] == '16:00:00') {
                  this.psych_consultate_hours.hours16 = false;
                  document.getElementById('time-consultation-16').checked = false
                }
                if (psych_busy_tyme_aray[i] == '17:00:00') {
                  this.psych_consultate_hours.hours17 = false;
                  document.getElementById('time-consultation-17').checked = false
                }
                if (psych_busy_tyme_aray[i] == '18:00:00') {
                  this.psych_consultate_hours.hours18 = false;
                  document.getElementById('time-consultation-18').checked = false
                }
              }


            })
      }


    },
    deleteRequestTest(currentId) {
      let request;
      request = '/api/test_request/' + currentId + '?_method=PUT&comment_user=' + this.reason_for_cancellation + '&status_id=3&type_id=1'
      this.$http.post(window.API_ROOT + request)
          .then((res) => {
            Vue.toastr({
              message: this.$t('profile.settings.success') + '!',
              description: this.$t('profile.settings.note-delete'),
              type: 'success'
            });
            this.updateInfo()
            this.$bvModal.hide('modal-event-desc-test')
            this.showDeleteRequest = false
            this.reason_for_cancellation = ''
          }).catch((e) => {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.error'),
          type: 'error'
        });
        this.updateInfo()
        this.$bvModal.hide('modal-event-desc-test')
        this.showDeleteRequest = false
        this.reason_for_cancellation = ''

      });


    },
    deleteRequest(currentId) {
      let request;
      request = '/api/request/' + currentId + '?_method=PUT&comment_user=' + this.reason_for_cancellation + '&status_id=3&type_id=1';
      this.$http.post(window.API_ROOT + request)
          .then((res) => {
            Vue.toastr({
              message: this.$t('profile.settings.success') + '!',
              description: this.$t('profile.settings.note-delete'),
              type: 'success'
            });
            this.updateInfo()
            this.$bvModal.hide('modal-event-desc')
            this.showDeleteRequest = false
            this.reason_for_cancellation = ''
          }).catch((e) => {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.error'),
          type: 'error'
        });
        this.$bvModal.hide('modal-event-desc')
        this.showDeleteRequest = false
        this.reason_for_cancellation = ''

      });


    },
    selecthour(hour) {
      this.consultations.selected_hour = hour + ':00:00'
    },
    selecthourQabilet(hour) {
      this.QabiletPsychForm.hour = hour + ':00:00'
    },
    selecthourTestFunc(hour) {
      this.select_test_hour = hour + ':00:00'
    },
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },
    submitPsychQabilet() {
      if (this.psychDateConsultateQabilet == false || this.QabiletPsychForm.hour == false) {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.all-data-complete'),
          type: 'error'
        });
      } else if (this.can_create_request == false) {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.you-cant-create-request') + this.new_try_days_remaining + this.$t('profile.settings.you-cant-create-request1'),
          type: 'error'
        });
      } else {
        let request;
        request = '/api/request?user_id=' + this.consultations.user_id + '&psychologist_id=' + this.QabiletPsychForm.psych_id +
            '&regional_centre_id=' + this.QabiletPsychForm.adressQabilet + '&online=' + this.QabiletPsychForm.status + '&time=' + this.QabiletPsychForm.hour + '&date=' + this.psychDateConsultateQabilet + '&visit_purpose=' + this.QabiletPsychForm.visit_purpose + '&status_id=1&type_id=1';
        this.$http.post(window.API_ROOT + request)
            .then((res) => {
              Vue.toastr({
                message: this.$t('profile.settings.success') + '!',
                description: this.$t('profile.settings.register-success'),
                type: 'success'
              });
            }).catch((e) => {
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: res.body.message,
            type: 'error'
          });

        });
        this.$bvModal.hide('modal-register-for-consultation-qabilet');
        this.selectConsultationDateQabilet();
        this.qabilet_psych_consultate_hours.hours9 = true;
        this.qabilet_psych_consultate_hours.hours10 = true;
        this.qabilet_psych_consultate_hours.hours11 = true;
        this.qabilet_psych_consultate_hours.hours12 = true;
        this.qabilet_psych_consultate_hours.hours13 = true;
        this.qabilet_psych_consultate_hours.hours14 = true;
        this.qabilet_psych_consultate_hours.hours15 = true;
        this.qabilet_psych_consultate_hours.hours16 = true;
        this.qabilet_psych_consultate_hours.hours17 = true;
        this.qabilet_psych_consultate_hours.hours18 = true;
        document.getElementById('qabilet-time-consultation-9').checked = false
        document.getElementById('qabilet-time-consultation-10').checked = false
        document.getElementById('qabilet-time-consultation-11').checked = false
        document.getElementById('qabilet-time-consultation-12').checked = false
        document.getElementById('qabilet-time-consultation-13').checked = false
        document.getElementById('qabilet-time-consultation-14').checked = false
        document.getElementById('qabilet-time-consultation-15').checked = false
        document.getElementById('qabilet-time-consultation-16').checked = false
        document.getElementById('qabilet-time-consultation-17').checked = false
        document.getElementById('qabilet-time-consultation-18').checked = false
        this.QabiletPsychForm.psych_id = '';
        this.QabiletPsychForm.adressQabilet = '';
        this.QabiletPsychForm.status = 0;
        this.QabiletPsychForm.hour = '';
        this.psychDateConsultateQabilet = '';
        this.QabiletPsychForm.visit_purpose = '';
        this.updateInfo();

      }
    },

    submitPsych() {
      if (this.consultations.selected_hour == false || this.psychDateConsultate == false || this.consultations.visit_purpose == false) {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.all-data-complete'),
          type: 'error'
        })
      } else if (this.can_create_request == false) {
        Vue.toastr({
          message: this.$t('profile.settings.error'),
          description: this.$t('profile.settings.you-cant-create-request') + this.new_try_days_remaining + this.$t('profile.settings.you-cant-create-request1'),
          type: 'error'
        });
      } else {
        let request;
        request = '/api/request?user_id=' + this.consultations.user_id + '&psychologist_id=' + this.consultations.psych_id +
            '&regional_centre_id=0&online=0&time=' + this.consultations.selected_hour + '&date=' + this.psychDateConsultate + '&visit_purpose=' + this.consultations.visit_purpose + '&status_id=1&type_id=1';
        this.$http.post(window.API_ROOT + request)
            .then((res) => {
              Vue.toastr({
                message: this.$t('profile.settings.success') + '!',
                description: this.$t('profile.settings.register-success'),
                type: 'success'
              });
            }).catch((e) => {
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: res.body.message,
            type: 'error'
          });

        });
        this.$bvModal.hide('modal-register-for-consultation');
        this.psych_consultate_hours.hours9 = true;
        this.psych_consultate_hours.hours10 = true;
        this.psych_consultate_hours.hours11 = true;
        this.psych_consultate_hours.hours12 = true;
        this.psych_consultate_hours.hours13 = true;
        this.psych_consultate_hours.hours14 = true;
        this.psych_consultate_hours.hours15 = true;
        this.psych_consultate_hours.hours16 = true;
        this.psych_consultate_hours.hours17 = true;
        this.psych_consultate_hours.hours18 = true;
        document.getElementById('time-consultation-9').checked = false
        document.getElementById('time-consultation-10').checked = false
        document.getElementById('time-consultation-11').checked = false
        document.getElementById('time-consultation-12').checked = false
        document.getElementById('time-consultation-13').checked = false
        document.getElementById('time-consultation-14').checked = false
        document.getElementById('time-consultation-15').checked = false
        document.getElementById('time-consultation-16').checked = false
        document.getElementById('time-consultation-17').checked = false
        document.getElementById('time-consultation-18').checked = false
        this.consultations.selected_hour = '',
            this.psychDateConsultate = '',
            this.consultations.visit_purpose = '',
            this.updateInfo();
        this.selectConsultationDate();
      }
    },
  },

  computed: {
    abilityChooseThread: function () {
      if (Boolean(this.select_training_type) && Boolean(this.select_training_center) && Boolean(this.select_subject) && Boolean(this.select_lang)) {
        this.getThreads()
      } else {
        this.disabledThreadsList = true
      }
    }
  },
}
</script>


<style lang="postcss" scoped>

.time-schedules {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
}

.card-notes {
  padding: 10px;
  margin-bottom: 1rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
  border-radius: 10px
}

.btn-test {
  top: 386px !important;
}

.calendar-tab {
  /* max-width: 792px; */
  max-width: 1120px;
}

.delete-request {
  margin-top: 2rem;
  width: 100%;
  height: 44px;
}

/* custom-calendar  START*/
/deep/ .custom-calendar {
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
  font-family: "Roboto Condensed", sans-serif;
}

/deep/ .custom-calendar .vc-pane {
  padding: 0 !important;
}

.custom-calendar .day-content {
  padding-top: 7px;
  padding-left: 8px;
}

.custom-calendar .day-label {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #343F68;
}

.custom-calendar .event-day {
  height: 83px;
  margin-top: -32px;
  margin-left: -8px;
}

.consultation-bg {
  background-color: #109CC0;
  height: 100%;
  background-image: url("/images/profile/event-2.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.testing-bg {
  background-color: #04C2F4;
  height: 100%;
  background-image: url("/images/profile/event-1.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.training-bg {
  background-color: powderblue;
  height: 100%;
  background-image: url("/images/profile/event-2.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

/deep/ .custom-calendar .vc-day {
  width: 100% !important;
  height: 83px !important;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;

&
.vc-header {
  background-color: #f1f5f8;
  padding: 36px 0;
}

&
.vc-weeks {
  padding: 0;
  grid-template-rows: repeat(5) !important;
}

&
.vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}

&
.vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;

&
.weekday-1,

&
.weekday-7 {
  background-color: #eff8ff;
}

&
:not(.on-bottom) {
  border-bottom: var(--day-border);

&
.weekday-1 {
  border-bottom: var(--day-border-highlight);
}

}
&
:not(.on-right) {
  border-right: var(--day-border);
}

}
&
.vc-day-dots {
  margin-bottom: 5px;
}

}
/deep/ .custom-calendar .vc-title {
  padding: 36px 0 20px 0;
  font-weight: 700;
  font-size: 24px;
  color: #343F68;
}

/deep/ .custom-calendar .vc-arrows-container {
  padding: 35px 20px;
}

/deep/ .custom-calendar .vc-arrow:hover {
  background-color: #fff;
}

/deep/ .custom-calendar .is-today span {
  color: #fff !important;
  background-color: #04C2F4 !important;
  border-radius: 50% !important;
  padding: 2px !important;
}

/deep/ .custom-calendar .vc-day.is-not-in-month {
  background-color: #fff !important;
}

/deep/ .custom-calendar .vc-container {
  border: 1px solid #E7E8EA !important;
  border-radius: 5px !important;
  /* margin-left: 0!important; */
}

/deep/ .custom-calendar .vc-weekday {
  text-transform: uppercase !important;
  border: 1px solid #E7E8EA;
  padding: 11px 0;
}

/deep/ .custom-calendar .vc-weeks {
  grid-template-rows: repeat(5) !important;
}

/deep/ .custom-calendar .has-event {
  color: #fff;
  padding-top: 8px;
  padding-left: 4px;
  z-index: 100;
}

.event-day .day-label {
  color: transparent;
}

.testing-bg > .day-label,
.consultation-bg > .day-label {
  color: #fff;
  position: absolute;
  top: 7px;
  left: 8px;
}

.is-today .event-day {
  margin-top: -8px;
}

.custom-calendar .is-today > .day-content .day-label {
  color: #fff !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  padding: 0px !important;
}

.custom-calendar .is-today > .day-content:before {
  content: '';
  display: inline-block;
  width: 26px;
  height: 26px;
  background-color: #04C2F4;
  border-radius: 50%;
  /*left: 4px;*/
  left: 8px;
  top: 6px;
  z-index: 1000 !important;
  position: absolute;
}

.custom-calendar .is-today > .day-content > .day-label {
  margin-left: -4px;
  z-index: 1000 !important;
  position: absolute;
  width: 26px !important;
  display: flex;
  justify-content: center;
}

/deep/ .calendar-test-date .is-today > .vc-day-content {
  color: #04C2F4 !important;
  background-color: #fff !important;
}

/deep/ .calendar-test-date .is-today > .vc-day-content:focus {
  color: #fff !important;
  background-color: #04C2F4 !important;
}

/* custom-calendar  END*/
.consultation-box, .testing-box, .training-box {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: #109CC0;
}

.consultation-box {
  background: #109CC0;
}

.testing-box {
  background: #04C2F4;
}

.training-box {
  background: powderblue;
}

.register-button {
  border: none;
  background-color: #fdfcfe;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: #343F68;
  line-height: 21px;
}

.register-button > div:hover {
  text-decoration: underline;
}

.register-modal .form-group {
  width: 312px !important;
}

.register-modal .modal-body {
  padding: 20px !important;
}

.register-modal .modal .modal-body {
  padding: 20px !important;
}

.notes-add-btn {
  display: none;
}

.modal-register-mob-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #343F68;
  padding-top: 20px;
  padding-bottom: 24px;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.form-check-title {
  padding-top: 2px;
  letter-spacing: 0.2px;
  font-size: 15px;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #04C2F4;
}

.form-check-label input:checked ~ .form-check-title {
  color: #04C2F4;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
}

#modal-register-mob .btn-blue, #modal-register-mob .btn-white {
  width: 120px;
  padding: 12px 0;
}

#modal-register-mob .btn-blue {
  margin-right: 32px;
}

.select-type-mob {
  display: none;
}

.select-type {
  display: flex;
  flex-direction: row;
}

.btn-register--mob {
  display: none;
}

.notes-tabs {
  max-width: 1120px;
}

.notes-tabs .tabsMenu {
  width: 100%;
}

@media (min-width: 1200px) {
  /* .notes-wrapper {
    margin-left: 101px;
  } */
}

@media (max-width: 991px) {
  .calendar-tab {
    margin: 0 auto;
  }

  .register-button {
    margin-left: 24px;
  }

  #modal-register .modal-dialog {
    max-width: 346px !important;
  }

  #modal-register .modal-content {
    max-width: 346px;
    margin: 0 auto;
  }

  #modal-register .modal-register-col {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    max-width: 345px !important;
    margin: 0 auto;
  }

  .register-modal .select-test-day {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .testing-offline-title {
    text-align: center;
  }

  /* /deep/ .calendar-test-date .vc-day-box-center-center {
      border-radius: 50%;
  }
  /deep/ .calendar-test-date .vc-day-content:hover {
      border-radius: 50%;
  }
  /deep/ .calendar-test-date .is-today > .vc-day-content:focus, .calendar-test-date .vc-day-content:focus {
      border-radius: 50%!important;
  }
  /deep/ .calendar-test-date .vc-pane {
      box-shadow: 0px 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
  }
  /deep/ .calendar-test-date .vc-container {
      border: 1px solid #fff;
  } */
  .btn-register--mob {
    display: block;
  }
}

@media (max-width: 767px) {
  .notes-add-btn {
    display: block
  }

  .notes-wrapper .tab-content {
    padding-top: 24px;
  }

  /deep/ .vc-header {
    padding: 0 !important;
  }

  /deep/ .vc-arrow {
    height: auto !important;
  }

  /deep/ .custom-calendar .vc-arrows-container {
    padding: 0px 50px !important;
  }

  /deep/ .custom-calendar .vc-title {
    padding: 5px 0 20px 0 !important;
    font-size: 18px;
    line-height: 21px;
  }

  .calendar-tab {
    max-width: 360px;
    margin: 0 auto;
    position: relative;
  }

  /deep/ .custom-calendar .vc-day {
    border: none;
    height: 28px !important;
  }

  /deep/ .custom-calendar .vc-weekday {
    border: 1px solid #fff;
    color: #BCC1CD !important;
    padding: 11px 0;
    text-transform: capitalize !important;
  }

  .custom-calendar.vc-container {
    border: 1px solid #fff !important;
  }

  .custom-calendar .day-content {
    padding: 0;
    text-align: center;
  }

  .custom-calendar .day-label {
    font-size: 16px;
  }

  /deep/ .custom-calendar .event-day {
    height: 28px !important;
    /*  */
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .testing-bg {
    background-color: transparent;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-image: none;
  }

  .consultation-bg {
    background-color: transparent;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-image: none;
  }

  .custom-calendar .day-content .testing-bg:before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 26px;
    background-color: #04C2F4;
    border-radius: 50%;
    /* left: 4px;
    top: 6px; */
    left: 12px;
    top: 0px;
    z-index: 1000 !important;
    position: absolute;
  }

  .custom-calendar .day-content .consultation-bg:before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 26px;
    background-color: #109CC0;
    border-radius: 50%;
    /* left: 4px;
    top: 6px; */
    left: 12px;
    top: 0px;
    z-index: 1000 !important;
    position: absolute;
  }

  .custom-calendar .day-content .testing-bg .day-label,
  .custom-calendar .day-content .consultation-bg .day-label {
    color: #fff !important;
    z-index: 1000;
    display: flex;
    justify-content: center;
    width: 26px !important;
    /* margin-left: -4px; */
    margin-left: 4px;
    top: 1px;
  }

  .custom-calendar .is-today > .day-content:before {
    background-color: #fff;
  }

  .custom-calendar .is-today > .day-content > .day-label {
    color: #04C2F4 !important;
    width: 100% !important;
    margin-left: 0;
  }

  .register-buttons {
    display: none;
  }

  .notes-add-btn {
    /*display: block;*/
    border: none;
    background: transparent;
    padding: 0;
    position: absolute;
    z-index: 1;
    right: 0;
    outline: none;
  }

  .notes-tabs .nav-tabs .nav-item:first-child .nav-link {
    padding-left: 24px;
    padding-right: 24px;
  }

  .select-type {
    display: none;
  }

  .select-type-mob {
    display: block;
    width: 345px;
    padding: 0 16px 32px 16px;
    margin: 0 auto;
  }

  /deep/ .select-type-mob .el-radio-button {
    width: 50%;
  }

  /deep/ .select-type-mob .el-radio-button .el-radio-button__inner {
    padding: 11px 0;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #04C2F4;
    color: #04C2F4;
    font-size: 15px;
    line-height: 18px;
  }

  /deep/ .select-type-mob .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #04C2F4;
    border: 1px solid #fff;
    color: #fff;
  }

  .select-type-mob-desc {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #04C2F4;
    padding-top: 32px;
  }

  .register-modal .select-test-day {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  /deep/ .calendar-test-date .vc-header {
    padding-bottom: 16px !important;
  }

  .register-modal .select-test-day {
    top: 476px;
  }

  /deep/ .calendar-test-date .vc-arrow {
    width: 13px !important;
    height: 43px !important;
  }
}

</style>
<style lang="css">
.el-select-dropdown {
  max-width: 312px;
}

.el-select-dropdown__item {
  overflow: visible;
}

.modal-event-desc-title {
  color: #109CC0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 0;
}

</style>